import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from '../components/AuthContext/AuthContext';
import logo_ligthmode from "../images/logo_icon_black.svg";
import logo_darkmode from "../images/logo_icon_white.svg";
import userprofile from "../images/userprofile.png";
import "./sidebar.css";
import { Link, useLocation } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from "react-redux";
import { Popover } from 'react-tiny-popover';

const Sidebar = () => {

    const tooltipPlacement = "right";
    const query = useQuery();
    const location = useLocation();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const [sidebarCloseStatus, setSidebarCloseStatus] = useState(document.body.classList.contains("sidebar-close"));
    const [openedPage, setOpenedPage] = useState(location && location.pathname && location.pathname.slice(1) ? location.pathname.slice(1) : "");
    const [checkScreenWidthValue, setCheckScreenWidthValue] = useState("");

    const withdrawRequestsData = useSelector((state) => state.kprice.withdrawRequests.data);

    const { user, signOut } = useContext(AuthContext);

    const supportActiveTickets = useSelector((state) => state.kprice.supportActiveTickets.data);
    const [supportSignal, setSupportSignal] = useState(false);

    useEffect(() => {
        let activeTickets = supportActiveTickets && supportActiveTickets.support_requests ? supportActiveTickets.support_requests : [];
        let tempSupportSignal = false;
        
        activeTickets.map((item) => {
            if(item.last_event_user_type == "Customer"){
                tempSupportSignal = true;
            }
        });

        setSupportSignal(tempSupportSignal);
    }, [supportActiveTickets]);

    const linkControl = (e, link) => {
        if(location.pathname === link){
            e.preventDefault();
        }
        else if(link == "spot" && location.pathname == "/spot"){
            if(query.get("type") == "all"){
                e.preventDefault();
            }
        }
        else if(link == "deposit" && location.pathname == "/deposit"){
            if(query.get("customer_id") == null){
                e.preventDefault();
            }
        }
        else if(link == "withdraw" && location.pathname == "/withdraw"){
            if(query.get("customer_id") == null){
                e.preventDefault();
            }
        }
        else if((link == "last-deposits" && location.pathname == "/last-deposits") || (link == "deposits" && location.pathname == "/deposits") || 
        (link == "last-withdraws" && location.pathname == "/last-withdraws") || (link == "withdraws" && location.pathname == "/withdraws") || 
        (link == "last-transfers" && location.pathname == "/last-transfers") || (link == "transfers" && location.pathname == "/transfers") || 
        (link == "last-spot-orders" && location.pathname == "/last-spot-orders") || (link == "spot-orders" && location.pathname == "/spot-orders") || 
        (link == "last-future-orders" && location.pathname == "/last-future-orders") || (link == "future-orders" && location.pathname == "/future-orders")){
            if(query.get("customer_id") == null || query.get("customer_id") == ""){
                e.preventDefault();
            }
        }
    };
    
    document.addEventListener("wheel", function(event){//type bilgisi number olan elementlerde scroll işlemi engellendi (input number'larda scroll ile bilgi değişmemesi için)
        if(document.activeElement.type === "number"){
            document.activeElement.blur();
        }
    });

    useEffect(() => {
        // Observer'ın callback fonksiyonu
        const callback = (mutationsList, observer) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                    setSidebarCloseStatus(document.body.classList.contains("sidebar-close"));
                }
            }
        };
    
        // MutationObserver'ı başlatma
        const observer = new MutationObserver(callback);
    
        // `body` elementini ve hangi değişiklikleri dinlemek istediğimizi tanımlama
        observer.observe(document.body, { attributes: true });

        const checkScreenWidth = () => {
            setOpenMenu(null);
            if (window.innerWidth <= 1200 && checkScreenWidthValue != "down") {
                document.body.classList.add('sidebar-close');
                setSidebarCloseStatus(true);
                setCheckScreenWidthValue("down");
            }
            else if (window.innerWidth > 1200 && checkScreenWidthValue != "up") {
                setSidebarCloseStatus(document.body.classList.contains("sidebar-close"));
                setCheckScreenWidthValue("up");
            }
        };
        
        checkScreenWidth();
        
        window.addEventListener('resize', checkScreenWidth);
        
        // Component temizlenirken observer'ı durdurma ve ekran genişliğini dinlemeyi temizleme
        return () => {
            observer.disconnect();
            window.removeEventListener('resize', checkScreenWidth);
        }
    }, [checkScreenWidthValue]);

    useEffect(() => {
        setOpenedPage(location.pathname.slice(1));
    }, [location]);

    const [openMenu, setOpenMenu] = useState(null);
    // Bir menü öğesini açma/kapama işlevi
    const toggleMenu = (menuName) => {
        setOpenMenu((prev) => (prev === menuName ? null : menuName));
    };

    // Sayfa genelindeki tıklamaları dinleyerek menüleri kapama işlevi
    const handleClickOutside = (event) => {
        if (!event.target.closest(".with-submenu")) {
            setOpenMenu(null);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <div id="sidebar" className="sidebar scrollbarhide">
            <Link to='/' className="sidebar-logo" title="Logo">
                <img src={logo_ligthmode} alt="logo" className="logo-lightmode"/>
                <img src={logo_darkmode} alt="logo" className="logo-darkmode"/>
                <div>PRICE</div>
            </Link>
            <div className="sidebar-openclose" title="Sidebar Open/Close" onClick={() => document.body.classList.toggle("sidebar-close")}>
                <i className="fa-solid fa-arrow-right" style={sidebarCloseStatus ? undefined : {display: "none"}}></i>
                <i className="fa-solid fa-arrow-left" style={sidebarCloseStatus ? {display: "none"} : undefined}></i>
            </div>
            <div className="sidebar-links">
                <Tooltip title={sidebarCloseStatus ? "Dashboard" : undefined} placement={tooltipPlacement}>
                    <Link to='/' className={openedPage == "" ? "active" : undefined} onClick={(e) => linkControl(e, '/')}>
                        <div className="info">
                            <div className="mark"></div>
                            <i className="fa-solid fa-house"></i>
                            <span>Dashboard</span>
                        </div>
                    </Link>
                </Tooltip>
                <Tooltip title={sidebarCloseStatus ? "Users" : undefined} placement={tooltipPlacement}>
                    <Link to="/users" className={openedPage == "users" ? "active" : undefined} onClick={(e) => linkControl(e, '/users')}>
                        <div className="info">
                            <div className="mark"></div>
                            <i className="fa-solid fa-users"></i>
                            <span>Users</span>
                        </div>
                    </Link>
                </Tooltip>
                <Tooltip title={sidebarCloseStatus ? "General List" : undefined} placement={tooltipPlacement}>
                    <Link to="/general-list" className={openedPage == "general-list" ? "active" : undefined} onClick={(e) => linkControl(e, '/general-list')}>
                        <div className="info">
                            <div className="mark"></div>
                            <i className="fa-solid fa-users-viewfinder"></i>
                            <span>General List</span>
                        </div>
                    </Link>
                </Tooltip>
                <Tooltip title={sidebarCloseStatus ? "Deposit" : undefined} placement={tooltipPlacement}>
                    <Link to="/deposit" className={openedPage == "deposit" ? "active" : undefined} onClick={(e) => linkControl(e, 'deposit')}>
                        <div className="info">
                            <div className="mark"></div>
                            <i className="fa-solid fa-money-bill"></i>
                            <span>Deposit</span>
                        </div>
                    </Link>
                </Tooltip>
                <Tooltip title={sidebarCloseStatus ? "Deposit Accounts" : undefined} placement={tooltipPlacement}>
                    <Link to="/deposit-accounts" className={openedPage == "deposit-accounts" ? "active" : undefined} onClick={(e) => linkControl(e, '/deposit-accounts')}>
                        <div className="info">
                            <div className="mark"></div>
                            <i className="fa-solid fa-wallet"></i>
                            <span>Deposit Accounts</span>
                        </div>
                    </Link>
                </Tooltip>
                <Tooltip title={sidebarCloseStatus ? "Withdraw" : undefined} placement={tooltipPlacement}>
                    <Link to="/withdraw" className={openedPage == "withdraw" ? "active" : undefined} onClick={(e) => linkControl(e, 'withdraw')}>
                        <div className="info">
                            <div className="mark"></div>
                            <i className="fa-solid fa-hand-holding-dollar"></i>
                            <span>Withdraw</span>
                        </div>
                    </Link>
                </Tooltip>
                <Tooltip title={sidebarCloseStatus ? "Withdraw Requests" : undefined} placement={tooltipPlacement}>
                    <Link to="/withdraw-requests" className={openedPage == "withdraw-requests" ? "active" : undefined} onClick={(e) => linkControl(e, '/withdraw-requests')}>
                        <div className="info">
                            <div className="mark"></div>
                            <i className="fa-solid fa-file-invoice-dollar"></i>
                            <span>Withdraw Requests</span>
                            {withdrawRequestsData && withdrawRequestsData.count && withdrawRequestsData.count > 0 ? <div className="count">{withdrawRequestsData.count}</div> : ""}
                        </div>
                    </Link>
                </Tooltip>
                <Tooltip title={sidebarCloseStatus ? "Spot" : undefined} placement={tooltipPlacement}>
                    <Link to="/spot?type=all" className={openedPage == "spot" ? "active" : undefined} onClick={(e) => linkControl(e, 'spot')}>
                        <div className="info">
                            <div className="mark"></div>
                            <i className="fa-solid fa-coins"></i>
                            <span>Spot</span>
                        </div>
                    </Link>
                </Tooltip>
                <Popover
                    isOpen={openMenu == "MenuDepositHistory"}
                    position={['right']} // Sağ tarafa açılmasını sağlar
                    content={
                        <div className="submenu">
                            <Link to="/last-deposits" className={openedPage == "last-deposits" ? "active" : undefined} onClick={(e) => linkControl(e, 'last-deposits')}>
                                <i className="fa-solid fa-file-import"></i>
                                <span>Last Deposits</span>
                            </Link>
                            <Link to="/deposits" className={openedPage == "deposits" ? "active" : undefined} onClick={(e) => linkControl(e, 'deposits')}>
                                <i className="fa-solid fa-money-bill-trend-up"></i>
                                <span>Deposits</span>
                            </Link>
                        </div>
                    }
                >
                    <Tooltip title={sidebarCloseStatus && openMenu != "MenuDepositHistory" ? "Deposit History" : undefined} placement={tooltipPlacement}>
                        <a className={openedPage == "last-deposits" || openedPage == "deposits" || openMenu == "MenuDepositHistory" ? "with-submenu active" : "with-submenu"} onClick={() => toggleMenu("MenuDepositHistory")}>
                            <div className="info">
                                <div className="mark"></div>
                                <i className="fa-solid fa-clock-rotate-left"></i>
                                <span>Deposit History</span>
                            </div>
                            <i className="fa-solid fa-chevron-right submenu-arrow"></i>
                        </a>
                    </Tooltip>
                </Popover>
                <Popover
                    isOpen={openMenu == "MenuWithdrawHistory"}
                    position={['right']} // Sağ tarafa açılmasını sağlar
                    content={
                        <div className="submenu">
                            <Link to="/last-withdraws" className={openedPage == "last-withdraws" ? "active" : undefined} onClick={(e) => linkControl(e, 'last-withdraws')}>
                                <i className="fa-solid fa-file-export"></i>
                                <span>Last Withdraws</span>
                            </Link>
                            <Link to="/withdraws" className={openedPage == "withdraws" ? "active" : undefined} onClick={(e) => linkControl(e, 'withdraws')}>
                                <i className="fa-solid fa-coins"></i>
                                <span>Withdraws</span>
                            </Link>
                        </div>
                    }
                >
                    <Tooltip title={sidebarCloseStatus && openMenu != "MenuWithdrawHistory" ? "Withdraw History" : undefined} placement={tooltipPlacement}>
                        <a className={openedPage == "last-withdraws" || openedPage == "withdraws" || openMenu == "MenuWithdrawHistory" ? "with-submenu active" : "with-submenu"} onClick={() => toggleMenu("MenuWithdrawHistory")}>
                            <div className="info">
                                <div className="mark"></div>
                                <i className="fa-solid fa-clock-rotate-left"></i>
                                <span>Withdraw History</span>
                            </div>
                            <i className="fa-solid fa-chevron-right submenu-arrow"></i>
                        </a>
                    </Tooltip>
                </Popover>
                <Popover
                    isOpen={openMenu == "MenuTransferHistory"}
                    position={['right']} // Sağ tarafa açılmasını sağlar
                    content={
                        <div className="submenu">
                            <Link to="/last-transfers" className={openedPage == "last-transfers" ? "active" : undefined} onClick={(e) => linkControl(e, 'last-transfers')}>
                                <i className="fa-solid fa-file-invoice-dollar"></i>
                                <span>Last Transfers</span>
                            </Link>
                            <Link to="/transfers" className={openedPage == "transfers" ? "active" : undefined} onClick={(e) => linkControl(e, 'transfers')}>
                                <i className="fa-solid fa-money-bill-transfer"></i>
                                <span>Transfers</span>
                            </Link>
                        </div>
                    }
                >
                    <Tooltip title={sidebarCloseStatus && openMenu != "MenuTransferHistory" ? "Transfer History" : undefined} placement={tooltipPlacement}>
                        <a className={openedPage == "last-transfers" || openedPage == "transfers" || openMenu == "MenuTransferHistory" ? "with-submenu active" : "with-submenu"} onClick={() => toggleMenu("MenuTransferHistory")}>
                            <div className="info">
                                <div className="mark"></div>
                                <i className="fa-solid fa-clock-rotate-left"></i>
                                <span>Transfer History</span>
                            </div>
                            <i className="fa-solid fa-chevron-right submenu-arrow"></i>
                        </a>
                    </Tooltip>
                </Popover>
                <Popover
                    isOpen={openMenu == "MenuSpotOrdersHistory"}
                    position={['right']} // Sağ tarafa açılmasını sağlar
                    content={
                        <div className="submenu">
                            <Link to="/last-spot-orders" className={openedPage == "last-spot-orders" ? "active" : undefined} onClick={(e) => linkControl(e, 'last-spot-orders')}>
                                <i className="fa-solid fa-file-invoice"></i>
                                <span>Last Spot Orders</span>
                            </Link>
                            <Link to="/spot-orders" className={openedPage == "spot-orders" ? "active" : undefined} onClick={(e) => linkControl(e, 'spot-orders')}>
                                <i className="fa-solid fa-arrow-right-arrow-left"></i>
                                <span>Spot Orders</span>
                            </Link>
                        </div>
                    }
                >
                    <Tooltip title={sidebarCloseStatus && openMenu != "MenuSpotOrdersHistory" ? "Spot Orders History" : undefined} placement={tooltipPlacement}>
                        <a className={openedPage == "last-spot-orders" || openedPage == "spot-orders" || openMenu == "MenuSpotOrdersHistory" ? "with-submenu active" : "with-submenu"} onClick={() => toggleMenu("MenuSpotOrdersHistory")}>
                            <div className="info">
                                <div className="mark"></div>
                                <i className="fa-solid fa-clock-rotate-left"></i>
                                <span>Spot Orders History</span>
                            </div>
                            <i className="fa-solid fa-chevron-right submenu-arrow"></i>
                        </a>
                    </Tooltip>
                </Popover>
                <Popover
                    isOpen={openMenu == "MenuFutureOrdersHistory"}
                    position={['right']} // Sağ tarafa açılmasını sağlar
                    content={
                        <div className="submenu">
                            <Link to="/last-future-orders" className={openedPage == "last-future-orders" ? "active" : undefined} onClick={(e) => linkControl(e, 'last-future-orders')}>
                                <i className="fa-solid fa-file-waveform"></i>
                                <span>Last Future Orders</span>
                            </Link>
                            <Link to="/future-orders" className={openedPage == "future-orders" ? "active" : undefined} onClick={(e) => linkControl(e, 'future-orders')}>
                                <i className="fa-solid fa-arrow-trend-up"></i>
                                <span>Future Orders</span>
                            </Link>
                        </div>
                    }
                >
                    <Tooltip title={sidebarCloseStatus && openMenu != "MenuFutureOrdersHistory" ? "Future Orders History" : undefined} placement={tooltipPlacement}>
                        <a className={openedPage == "last-future-orders" || openedPage == "future-orders" || openMenu == "MenuFutureOrdersHistory" ? "with-submenu active" : "with-submenu"} onClick={() => toggleMenu("MenuFutureOrdersHistory")}>
                            <div className="info">
                                <div className="mark"></div>
                                <i className="fa-solid fa-clock-rotate-left"></i>
                                <span>Future Orders History</span>
                            </div>
                            <i className="fa-solid fa-chevron-right submenu-arrow"></i>
                        </a>
                    </Tooltip>
                </Popover>
                <Popover
                    isOpen={openMenu == "MenuFuturePositionHistory"}
                    position={['right']} // Sağ tarafa açılmasını sağlar
                    content={
                        <div className="submenu">
                            <Link to="/last-future-positions" className={openedPage == "last-future-positions" ? "active" : undefined} onClick={(e) => linkControl(e, 'last-future-positions')}>
                                <i className="fa-solid fa-file-contract"></i>
                                <span>Last Future Positions</span>
                            </Link>
                            <Link to="/future-positions" className={openedPage == "future-positions" ? "active" : undefined} onClick={(e) => linkControl(e, 'future-positions')}>
                                <i className="fa-solid fa-chart-line"></i>
                                <span>Future Positions</span>
                            </Link>
                        </div>
                    }
                >
                    <Tooltip title={sidebarCloseStatus && openMenu != "MenuFuturePositionHistory" ? "Future Positions History" : undefined} placement={tooltipPlacement}>
                        <a className={openedPage == "last-future-positions" || openedPage == "future-positions" || openMenu == "MenuFuturePositionHistory" ? "with-submenu active" : "with-submenu"} onClick={() => toggleMenu("MenuFuturePositionHistory")}>
                            <div className="info">
                                <div className="mark"></div>
                                <i className="fa-solid fa-clock-rotate-left"></i>
                                <span>Future Positions History</span>
                            </div>
                            <i className="fa-solid fa-chevron-right submenu-arrow"></i>
                        </a>
                    </Tooltip>
                </Popover>
                <Tooltip title={sidebarCloseStatus ? "Active Future Positions" : undefined} placement={tooltipPlacement}>
                    <Link to="/active-future-positions" className={openedPage == "active-future-positions" ? "active" : undefined} onClick={(e) => linkControl(e, '/active-future-positions')}>
                        <div className="info">
                            <div className="mark"></div>
                            <i className="fa-solid fa-chart-line"></i>
                            <span>Active Future Positions</span>
                        </div>
                    </Link>
                </Tooltip>
                <Tooltip title={sidebarCloseStatus ? "Tickets" : undefined} placement={tooltipPlacement}>
                    <Link to="/tickets" className={openedPage == "tickets" ? "active" : undefined} onClick={(e) => linkControl(e, '/tickets')}>
                        <div className="info">
                            <div className="mark"></div>
                            <i className="fa-solid fa-comment-dots"></i>
                            <span>Tickets</span>
                            {supportSignal && <div className="signal"><i className="fa-solid fa-exclamation"></i></div>}
                        </div>
                    </Link>
                </Tooltip>
            </div>
            <div className="sidebar-links">
                <Tooltip title={sidebarCloseStatus ? "Sign Out" : undefined} placement={tooltipPlacement}>
                    <a onClick={signOut}>
                        <div className="info">
                            <div className="mark"></div>
                            <i className="fa-solid fa-right-from-bracket"></i>
                            <span>Sign Out</span>
                        </div>
                    </a>
                </Tooltip>
            </div>
            <Tooltip title={sidebarCloseStatus && user && user.username ? user.username : undefined} placement={tooltipPlacement}>
                <div className="sidebar-user">
                    <div className="user-img">
                        <div className="image">
                            <img src={userprofile} />
                        </div>
                    </div>
                    <div className="user-info">
                        <label>{user && user.username ? user.username : undefined}</label>
                        <label>{user && user.attributes && user.attributes.masked_email ? user.attributes.masked_email : undefined}</label>
                    </div>
                </div>
            </Tooltip>
        </div>
    );
}

export default Sidebar;
